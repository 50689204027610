import React, {Component} from 'react';
import './Hero.css'
import rightBackground from './rightBackground.png'
import DownloadButton from "../../DownloadButton/DownloadButton";
class HeroPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="HeroPage_Main" className="container">
                <div id="HeroPage_Left" className="box">
                    <div id="Left_Text">
                        <h1>Meet <mark class="accent">elicks</mark></h1>
                        <h2>Your personal lick book. Search licks, read music, take notes, and create recordings.</h2>
                        <div id="Img_Container">
                            <DownloadButton width="100%"/>

                        </div>

                    </div>

                </div>
                <div id="HeroPage_Right" className="box">
                    <img src={rightBackground} alt="elicks app on various iOS devices" width="100%"/>
                </div>
            </div>
        );
    }
}

export default HeroPage;