import logo from './logo.svg';
import './App.css';
import React from 'react';

import HeroPage from './Components/Pages/Hero/HeroPage.js'
import NavigationBar from "./Components/NavigationBar/NavigationBar";
import FeaturesPage from "./Components/Pages/Features/FeaturesPage";
import FooterBar from "./Components/FooterBar/FooterBar";
import ReactDOM from "react-dom/client";
import Packs from "./Components/Pages/Packs/Packs"
import {BrowserRouter, Routes, Route, HashRouter} from "react-router-dom";
export default function App() {
    return (
        // <BrowserRouter>
        <div>
            <NavigationBar/>
            <div id="content">
                {/*<BrowserRouter>*/}
                    <HashRouter basename="/">
                        <Routes>
                            <Route path="/packs" element={<Packs />} />

                            <Route exact path="/" element={
                                <div>
                                    {/*<Packs/>*/}
                                    <HeroPage/>
                                    <FeaturesPage/>
                                </div>
                            } />
                        </Routes>
                    </HashRouter>
                {/*</BrowserRouter>*/}
            </div>
            <FooterBar/>
        </div>
        // </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
