import React, {Component} from 'react';
import './Packs.css'
import rightBackground from './rightBackground.png'
import DownloadButton from "../../DownloadButton/DownloadButton";
class Packs extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="Packs_Main" className="container">
                <div id="Packs_Left" className="box">
                    <div id="Left_Text">

                        <h1>Download packs</h1>
                        <p>Packs contain a collection of licks which can be downloaded and imported into elicks. Packs are completely free and available for download here. There will be packs available for different instruments and skill levels with more to come!</p>
                        <br/>
                        <br/>
                        <h3>Fundamentals Packs</h3>
                        <p>Fundamental packs are specific to a given instrument and designed for beginners. They contain basic exercises for instrument such as long tones and lip slurs as well as beginning scales.</p>
                        <br/>
                        <a href="/Pack_Download/Piano_Fundamentals_Pack.licks">Piano Fundamentals Pack</a>
                        <br/>
                        <a href="/Pack_Download/Saxophone_Fundamentals_Pack.licks">Saxophone Fundamentals Pack</a>
                        <br/>
                        <a href="/Pack_Download/Trombone_Fundamentals_Pack.licks">Trombone Fundamentals Pack</a>
                    </div>

                </div>
                <div id="Packs_Right" className="box">
                    <img src={rightBackground} alt="elicks app on various iOS devices" width="100%"/>
                </div>
            </div>
        );
    }
}

export default Packs;