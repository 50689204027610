import React, {Component} from 'react';
import './NavigationBar.css'
import Logo from './Black_Logo.png'
import Contact_Icon from './Contact_Icon.png'
import Download_Icon from './Download_Icon.png'
import Instagram_Icon from './Instagram_Icon.png'
class NavigationBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="NavigationBar_Main">
                <a href={"/"}>
                <div id="NavigationBar_Left">

                        <img src={Logo} alt="elicks logo"/>
                        <h3>elicks</h3>
                </div>
                </a>
                <div id="NavigationBar_Buttons">
                    <a href={"/"}>Home</a>
                    <a href={"/#/packs"}>Packs</a>

                </div>

                <div id="NavigationBar_Spacer">

                </div>
                <div id="NavigationBar_Right">
                    <a href="https://apps.apple.com/us/app/elicks/id1644578571">
                        <img src={Download_Icon} alt="Download Icon"/>
                    </a>
                    <a href="https://www.instagram.com/elicks.app/">
                        <img src={Instagram_Icon} alt="Instagram Icon"/>
                    </a>
                    <a href="mailto: nathan@elicksapp.com">
                        <img src={Contact_Icon} alt="Contact Icon"/>
                    </a>
                </div>

            </div>
        );
    }
}

export default NavigationBar;