import React, {Component} from 'react';
import './FooterBar.css'
class FooterBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="FooterBar_Main">
                <div id="FooterBar_Left">
                    Copyright © elicks 2023
                </div>
                <div id="FooterBar_Spacer"></div>
                <div id="FooterBar_Right">
                    <a href="https://apps.apple.com/us/app/elicks/id1644578571">Download</a>
                    <a href="https://www.instagram.com/elicks.app/">Instagram</a>
                    <a href="mailto: sherylhsu03@gmail.com">Contact</a>
                </div>
            </div>
        );
    }
}

export default FooterBar;