import React, {Component} from 'react';
import DownloadButtonImg from './DownloadButton.svg'

class DownloadButton extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="DownloadButton_Main">
                <a href='https://apps.apple.com/us/app/elicks/id1644578571'><img src={DownloadButtonImg} width={this.props.width}/></a>
            </div>
        );
    }
}

export default DownloadButton;