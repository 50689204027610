import React, {Component} from 'react';
import iPadImage from './iPad.png'
import './FeaturesPage.css'
class FeaturesPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="FeaturesPage_Main">
                <div id="FeaturesPage_Left">
                    <img id="iPad_Img" src={iPadImage} alt="iPad with elicks on it"/>
                </div>
                <div id="FeaturesPage_Right">
                    <div id="Right_Text">
                        <h3>Store Information</h3>
                        <ul>
                            <li>Assign tags</li>
                            <li>Take photos</li>
                            <li>Create and listen to recordings</li>
                            <li>Keep track of keys mastered in</li>
                            <li>Record notes</li>
                            <li>Save favorites</li>
                        </ul>
                        <br/>
                        <h3>Search intelligently</h3>
                        <ul>
                            <li>Search by name/notes, tags, keys, favorites</li>
                            <li>Sort in alphabetical order or last visited</li>
                            <li>Add multiple criteria (ex: licks in keys of C and Bb with tags 251 and bebop)</li>
                        </ul>
                        <br/>
                        <h3>Read music</h3>
                        <ul>
                            <li>Tap on any image to view in fullscreen mode</li>
                            <li>Rotates to maximize size</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default FeaturesPage;